import {
  createBrowserRouter,
  Navigate,
  Outlet,
  RouterProvider,
} from 'react-router-dom';
import { useMetadataUpdater } from './hooks/UseMetadataUpdater';
import { usePageTracking } from './hooks/UsePageTrasking';

const DefaultErrorElement = () => {
  return <Navigate to="/" />;
};

const MainEntry = () => {
  usePageTracking();
  useMetadataUpdater();

  return <Outlet />;
};

const router = createBrowserRouter([
  {
    path: '/',
    errorElement: <DefaultErrorElement />,
    element: <MainEntry />,
    children: [
      {
        path: '',
        errorElement: <DefaultErrorElement />,
        lazy: async () => {
          const { Index } = await import('./index/index');

          return { Component: Index };
        },
      },
      {
        path: 'pocket-thoughts',
        lazy: async () => {
          const { Index } = await import('./pocket-thoughts');

          return { Component: Index };
        },
      },
    ],
  },
]);

const App = () => {
  return <RouterProvider router={router} />;
};

export default App;

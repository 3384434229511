import { logEvent, setAnalyticsCollectionEnabled } from 'firebase/analytics';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { analytics } from '../firebase';

export const usePageTracking = () => {
  const location = useLocation();

  useEffect(() => {
    const pagePath = location.pathname + location.search;

    logEvent(analytics, 'page_view', {
      page_path: pagePath,
    });
  }, [location]);
};

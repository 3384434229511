import { initializeApp } from 'firebase/app';
import { getAnalytics } from 'firebase/analytics';

const firebaseConfig = {
  apiKey: 'AIzaSyCJIoGgfWbZY46V4XGVsbHVH4ENzYLDh68',
  projectId: 'veetechprojects',
  appId: '1:770010756197:web:1f1673bbbecbefe47d428c',
  measurementId: 'G-FT82ENN3YQ',
};

// Initialize Firebase

export const app = initializeApp(firebaseConfig);
export const analytics = getAnalytics(app);

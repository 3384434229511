import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

function updateMetaTag(attrName: string, attrValue: string, content: string) {
  let tag = document.querySelector(`meta[${attrName}='${attrValue}']`);
  if (tag) {
    tag.setAttribute('content', content);
  }
}

export const useMetadataUpdater = () => {
  const location = useLocation();

  useEffect(() => {
    const link = document.querySelector("link[rel~='icon']") as any;
    const locationElements = location.pathname.split('/');
    link.href = `/${locationElements[1] || 'index'}/favicon.ico`;

    const sPath =
      window.location.origin + `/${locationElements[1] || 'index'}/seo.json`;
    fetch(sPath)
      .then((r) => {
        if (!r.ok) {
          throw new Error('Network response was not ok ' + r.statusText);
        }
        return r.json();
      })
      .then((data) => {
        if (data.title) {
          document.title = data.title;
          updateMetaTag('name', 'description', data.description);
          updateMetaTag('property', 'og:title', data.title);
          updateMetaTag('property', 'og:description', data.description);
          updateMetaTag('property', 'og:image', data.thumbnail);
          updateMetaTag(
            'property',
            'og:url',
            window.location.origin + location.pathname
          );
          updateMetaTag('name', 'twitter:title', data.title);
          updateMetaTag('name', 'twitter:description', data.description);
          updateMetaTag('name', 'twitter:image', data.thumbnail);
        }
      })
      .catch(() => {
        // silence
      });
  }, [location]);
};
